.team-statement-section,
.meet-the-team-section {
  padding: 7.5rem;
}

.text-container {
  margin-bottom: 4rem;
}

.meet-the-team-section .img-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.team-member {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.meet-the-team-section .image {
  width: 100%;
  height: auto;
  display: block;
}

.team-member:hover .image {
  transform: scale(1.05);
}

.name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 3.6rem;
  font-weight: 600;
  text-align: center;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.team-member:hover .name {
  transform: translateY(0);
}

.video-section .text {
  margin-bottom: 1rem;
}
/* Video Section */
.youtube-container {
  display: flex;
  justify-content: center;
}

.youtube-container iframe {
  width: 90%;
  height: 55rem; /* Adjust the height as needed */
  border: none;
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
}

.navbar {
  -webkit-backdrop-filter: blur(30px) brightness(100%);
  backdrop-filter: blur(30px) brightness(100%);
  background-color: #111111cc;
  height: 6.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 0 2rem;
  transition: top 0.3s;
}

.navbar.sticky {
  position: sticky;
  /* top: -6.2rem; */
}

.navbar-container {
  display: flex;
  justify-content: space-between; /* Changed from center to space-between */
  align-items: center;
  height: 100%;
  max-width: 100%;
}

.navbar-logo,
.navbar-logo:hover {
  color: var(--text-default);
  cursor: pointer;
  text-decoration: none;
  font-size: 2.6rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  white-space: none;
}

/* Rest of your CSS remains unchanged */

.nav-menu {
  display: flex;
  /* grid-template-columns: repeat(4, auto); */
  /* grid-gap: 10px; */
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  align-items: center;
  height: 100%;
  /* margin-right: 2rem; */
}

.nav-item {
  height: 4rem;
  padding: 0 2rem;
  /* align-items: center; */
  align-self: center;
  /* width: 100%; */
}
.nav-links {
  color: var(--text-default);
  margin: 0 auto;
  display: flex;
  font-weight: 600;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  align-items: center;
  text-decoration: none;
  padding: 0.7rem 1rem;
  position: relative; /* Added position relative */
}

.nav-links::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: var(--pink-2);
  bottom: 0;
  left: 50%; /* Center the line */
  transform: translateX(-50%);
  transition: width 0.3s ease; /* Added transition */
}

.nav-links:hover::after {
  width: 100%; /* Expand width on hover */
}

.fa-bars {
  color: var(--text-default);
  /* height: rem; */
  font-size: 2.5rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 6.2rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;
    padding-top: 3rem;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--text-default);
    color: #242424;
    border-radius: 0;
  }

  .nav-item {
    height: 100%;
    /* padding: 0 2rem; */
    align-items: center;
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--text-default);
    font-size: 2.5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--text-default);
    padding: 14px 20px;
    border: 1px solid var(--pink-2);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #242424;
    /* color: #242424; */
    transition: 250ms;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Gemunu Libre", sans-serif;
  /* overflow-x: hidden; */
}

html {
  font-size: 62.5%;
  background-color: var(--background);
  color: var(--text-default);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #cbcbcb;
  transition: all 500ms ease-out;
}

a:hover {
  color: var(--pink-2);
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  background: none;
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(133, 133, 133, 0.2);
  border-radius: 10px;
  /* border: 3px solid rgba(0, 0, 0, 0.4); */
  /* box-shadow: 0 0 5px rgba(255, 255, 255, 0.3); */
  transition: all 300ms ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}

.text-container {
  display: flex;
  flex-direction: column;
  margin: 7.5rem;
  color: var(--text-default);
}

.section-container .text-container {
  margin-left: 0;
  margin-right: 0;
}

.text-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4.8rem;
  margin-bottom: 2.5rem;
  /* margin-top: 4rem; */
}

.text {
  /* font-weight: 700; */
  font-size: 3.2rem;
  line-height: 1.5;
  letter-spacing: 0.075rem;
  margin-bottom: 4rem;
}

.section {
  height: 100vw;
  /* padding: 7.5rem; */
}

.common-padding {
  margin: 8rem 10rem;
}

.pdf {
  /* width: 40rem; */
  /* width: 100%; */
  width: 60rem;
  height: 70rem;
  /* height: 100%; */
  /* height: 50rem; */
}

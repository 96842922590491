.image-container {
  position: relative;
  width: 100%;
  height: 26.5rem;
  background-color: #1a1a1a;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 141, 165, 0.45) 1.5%,
    rgba(6.54, 6.54, 6.54, 0.8) 100%
  );
  pointer-events: none;
  z-index: 99;
}

.cover-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .neon-lines {
  filter: drop-shadow(0 0 10px rgba(235, 109, 144, 0.8))
    drop-shadow(0 0 20px rgba(235, 109, 144, 0.8));
} */

.neon-lines {
  position: absolute;
  filter: drop-shadow(0 0 10px rgba(235, 109, 144, 0.8))
    drop-shadow(0 0 20px rgba(235, 109, 144, 0.8))
    drop-shadow(0 0 40px rgba(235, 109, 144, 0.8));
  z-index: 9;
}

.neon-lines.right {
  filter: drop-shadow(0 0 10px rgba(235, 109, 144, 0.8))
    drop-shadow(0 0 20px rgba(235, 109, 144, 0.8))
    drop-shadow(0 0 40px rgba(235, 109, 144, 0.8));
  float: right;
  right: 0;
}

.section-container .neon-lines {
  top: 0;
  left: -12.5rem;
  z-index: 2;
  margin-top: 7.5rem;
}

.section-container .neon-lines.right {
  top: 0;
  /* right: 100%; */
  margin-left: 48.37%;
  /* margin-left: auto; */
}
